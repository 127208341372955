<template>
  <div
    class="form-group d-flex flex-column justify-content-center px-8 px-lg-10"
  >
    <div class="row">
      <div class="col-12 col-md-6">
        <label class="d-block border-bottom">{{
          $t("branch_dash.customer_transaction")
        }}</label>
        <p>
          {{
            $t(
              `branch_dash.wants_to_${transactionSummary.selectedCustomerTransaction.type}`,
              { code: transactionSummary.selectedCustomerTransaction.code }
            )
          }}
        </p>
      </div>
      <div class="col-12 col-md-6">
        <label class="d-block border-bottom">{{
          $t("commons.commission")
        }}</label>
        <p>{{ transactionSummary.fee_formatted }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <label class="d-block border-bottom">{{
          $t("branch_dash.current_price")
        }}</label>
        <p>{{ transactionSummary.price_formatted }}</p>
      </div>
      <div class="col-12 col-md-6">
        <label class="d-block border-bottom">{{
          $t("branch_dash.amount")
        }}</label>
        <p>{{ transactionSummary.amount_str }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <label class="d-block border-bottom">{{
          $t("branch_dash.sub_total")
        }}</label>
        <p>{{ transactionSummary.subTotal_formatted }}</p>
      </div>
      <div class="col-12 col-md-6">
        <label class="d-block border-bottom">{{
          $t("branch_dash.commission_amount")
        }}</label>
        <p>{{ transactionSummary.fee_formatted }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <label class="d-block border-bottom">{{
          $t("branch_dash.total_volume")
        }}</label>
        <p>{{ transactionSummary.grandTotal_formatted }}</p>
      </div>
      <div class="col-12 col-md-6">
        <label
          class="d-block border-bottom"
          v-if="transactionSummary.selectedTransactionType === 'sell'"
          >{{ $t("branch_dash.payable_to_customer") }}</label
        >
        <label
          class="d-block border-bottom"
          v-if="transactionSummary.selectedTransactionType === 'buy'"
          >{{ $t("branch_dash.customer_payment") }}</label
        >
        <p>{{ transactionSummary.grandTotal_formatted }}</p>
      </div>

      <div class="col-12">
        <label class="d-block border-bottom"
          >{{ $t("branch_dash.inc_commission") }}:
          {{ $t(transactionSummary.includeFee) }}</label
        >
      </div>
    </div>

    <h3 class="my-5 font-weight-bold text-dark">
      {{ $t("branch_dash.customer_information_title") }}
    </h3>

    <div
      class="row"
      v-if="transactionSummary.selectedTransactionType === 'sell'"
    >
      <div class="col-12 border-bottom">
        <label>{{ $t("commons.sender") }}:</label>
        <span class="ml-1">{{
          $t(transactionSummary.selectedWhoWillSent.name)
        }}</span>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-md-6">
        <label class="d-block border-bottom">{{
          $t("branch_dash.sales")
        }}</label>
        <p>{{ transactionSummary.customer.order_id }}</p>
      </div>
      <div class="col-12 col-md-6" v-if="transactionSummary.customer.transaction_branch_code">
        <label class="d-block border-bottom">{{
          $t("branch_dash.transaction_branch_code")
        }}</label>
        <p>{{ transactionSummary.customer.transaction_branch_code }}</p>
      </div>
      <div class="col-12 col-md-6">
        <label class="d-block border-bottom">{{
          $t("commons.name_surname")
        }}</label>
        <p>{{ transactionSummary.customer.fullname }}</p>
      </div>
      <div class="col-12 col-md-6">
        <label class="d-block border-bottom">{{
          $t("commons.mobile_phone")
        }}</label>
        <p>{{ transactionSummary.customer.phone }}</p>
      </div>
      <div class="col-12 col-md-6">
        <label class="d-block border-bottom">{{ $t("commons.e_mail") }}</label>
        <p>{{ transactionSummary.customer.email }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TransactionSummary",
  computed: {
    ...mapState({
      transactionSummary: (state) => state.transactions.transactionSummary,
    }),
  },
};
</script>

<style lang="scss" scoped>
label.d-block {
  padding-bottom: 0.25rem;
}
</style>